import React, { useState, useEffect } from "react";
import Papa from "papaparse";

import logo from "./logo.svg";
import "./App.css";
import Web3 from "web3";
import { stakingPoolABI, stakingAddress } from "./contract/staking";
import { lpPoolABI, lpAddress } from "./contract/lp";
import { web3State, loadBlockchain, readEvents } from "./web3";
import { lpDeposit } from "./data/lpDeposited";
import CsvDownload from "react-json-to-csv";
import Moralis from 'moralis';

const sum = (arr) => {
  let result = arr.reduce(function (a, b) {
    return a + b;
  }, 0);
  return result;
};

const jsonToCsv = async (data, setCsv) => {
  const result = await Papa.unparse(data, {
    skipEmptyLines: true,
    header: false,
  });
  const blob = new Blob([result], { type: "test/csv" });
  const url = URL.createObjectURL(blob);
  console.log(url, "csvData");
  setCsv(url);
};

function App() {

  const apiUrl = "https://glocomx.buildmydapp.co/"
  // const apiUrl = "http://localhost:8080/";
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  const handleTotalAmount = (arr) => {
    let totalAmount = arr
      .map((item) => item)
      .reduce((prev, next) => Number(prev) + Number(next));
    return totalAmount;
  };

  const [amountObj, setAmountObj] = useState({
    stakeDeposits: null,
    stakeWithdrawn: null,
    lpDeposits: null,
    lpWithdrawn: null,
  });
  const [userXoidEvents, setUserXoidEvents] = useState();
  const [userCtznEvents, setUserCtznEvents] = useState();
  const [ctznWillPayoutTotal, setCtznWillPayoutTotal] = useState();
  const [registeredWalletCounts, setRegisteredWalletCounts] = useState();
  const [usersOfLastWeek, setUsersOfLastWeek] = useState();
  const [csv, setCsv] = useState();

  let ownerAddress = "0x0b1d114d9249ad870F11543215722D7804CC3dbA".toLowerCase();
  const registeredUser = async () => {
    let registeredCount = await fetch(`${apiUrl}registered-users`);
    registeredCount = await registeredCount.json();
    setRegisteredWalletCounts(registeredCount);
  };
  const lastWeekUser = async () => {
    let usersOfLastWeek = await (
      await fetch(`${apiUrl}get-last-week-users`)
    ).json();
    setUsersOfLastWeek(usersOfLastWeek?.length);
    const jsonData = usersOfLastWeek.map((user) => {
      return {
        name: user.username,
        address: user.address.toString(),
        createdAt: user.createdAt,
      };
    });
    setCsv(jsonData);
  };
  useEffect(async () => {
   await loadBlockchain();
   console.log("missionOneCtznBalance", web3State.missionOneCtznBalance)
 
   registeredUser();
    lastWeekUser();

    let poolEvents = await fetch(`${apiUrl}pool-events`);
    poolEvents = await poolEvents.json();

    let ctznEvent = await fetch(`${apiUrl}ctzn-events`);
    ctznEvent = await ctznEvent.json();

    let xoidEvents = await fetch(`${apiUrl}xoids-events`);
    xoidEvents = await xoidEvents.json();

    const serverUrl = "https://8hpinqb5orb9.usemoralis.com:2053/server";
    const appId = "ttgOgpm94ddlxRHyktK11NEEzHTX7RV0RuCU8fou";
    Moralis.initialize(appId);
    Moralis.serverURL = serverUrl;
    const options = {
      chain: "mainnet",
      address: "0x6Dd2D9aEe249afE49f89D0A62f192c7233bB366e",
    };
    const nfts = await Moralis.Web3.getNFTs(options);
    console.log("nfts", nfts)
    const requestOptions = {
      method: 'POST',
      headers: {'Content-Type':'application/json'},
      body: JSON.stringify({
        nfts: nfts
      })
    };
    let ctznWillPayout = await fetch(`${apiUrl}ctzn-will-payout`, requestOptions);
    ctznWillPayout = await ctznWillPayout.json();
    console.log("ctznWillPayout", ctznWillPayout)
    // await jsonToCsv(jsonData, setCsv)

    //pool events
    let stakeDeposits = [];
    let stakeWithdrawn = [];
    let lpDeposits = [];
    let lpWithdrawn = [];
    for (let k = 0; k < poolEvents.length; k++) {
      if (
        poolEvents[k].poolAddress.toLowerCase() ==
          web3State.stakingAddress.toLowerCase() &&
        poolEvents[k].event == "Deposited"
      ) {
        stakeDeposits.push(poolEvents[k].stakedAmount.toFixed(0));
      }

      if (
        poolEvents[k].poolAddress.toLowerCase() ==
          web3State.stakingAddress.toLowerCase() &&
        poolEvents[k].event == "Withdrawn"
      ) {
        stakeWithdrawn.push(poolEvents[k].stakedAmount.toFixed(0));
      }

      if (
        poolEvents[k].poolAddress.toLowerCase() ==
          web3State.lpAddress.toLowerCase() &&
        poolEvents[k].event == "Deposited"
      ) {
        lpDeposits.push(poolEvents[k].stakedAmount.toFixed(0));
      }

      if (
        poolEvents[k].poolAddress.toLowerCase() ==
          web3State.lpAddress.toLowerCase() &&
        poolEvents[k].event == "Withdrawn"
      ) {
        lpWithdrawn.push(poolEvents[k].stakedAmount.toFixed(0));
      }
    }
    setAmountObj({
      ...amountObj,
      stakeDeposits: handleTotalAmount(stakeDeposits),
      stakeWithdrawn: handleTotalAmount(stakeWithdrawn),
      lpDeposits: handleTotalAmount(lpDeposits),
      lpWithdrawn: handleTotalAmount(lpWithdrawn),
    });

    const xoidUserAddresses = [
      ...new Map(xoidEvents.map((item) => [item["from"], item])).values(),
    ];
    setUserXoidEvents(xoidUserAddresses);

    let ctznPaid = [];

    for (let i = 0; i < ctznEvent.length; i++) {
      ctznPaid.push(ctznEvent[i].value);
    }

    ctznPaid = sum(ctznPaid);
    ctznWillPayout = sum(ctznWillPayout);
    setUserCtznEvents(ctznPaid);
    setCtznWillPayoutTotal(ctznWillPayout);
  }, []);

  let regexVal = (val) => {
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  return (
    <div className="App">
      <div>
        <h3>
          CTZN CronJob Balance :{" "}
          {web3State.ctznBalance
            ? regexVal(web3State.ctznBalance)
            : "Loading..."}
        </h3>
        <h3>
          CTZN Staking Deposit :{" "}
          {amountObj.stakeDeposits
            ? regexVal(amountObj.stakeDeposits)
            : "Loading..."}
        </h3>

        <h3>
          CTZN Staking Withdrawn :{" "}
          {amountObj.stakeWithdrawn
            ? regexVal(amountObj.stakeWithdrawn)
            : "Loading..."}
        </h3>

        <h3>
          CTZN Staking Total :{" "}
          {amountObj.stakeDeposits
            ? regexVal(amountObj.stakeDeposits - amountObj.stakeWithdrawn)
            : "Loading..."}
        </h3>
        <h3>
          LP Deposit :{" "}
          {amountObj.lpDeposits ? regexVal(amountObj.lpDeposits) : "Loading..."}
        </h3>

        <h3>
          LP Withdrawn :{" "}
          {amountObj.lpWithdrawn
            ? regexVal(amountObj.lpWithdrawn)
            : "Loading..."}
        </h3>
        <h3>
          LP Total :{" "}
          {amountObj.lpDeposits
            ? regexVal(amountObj.lpDeposits - amountObj.lpWithdrawn)
            : "Loading..."}
        </h3>
        <h3>
          Last week users : {usersOfLastWeek ? usersOfLastWeek : "Loading..."}
          {usersOfLastWeek ? <CsvDownload data={csv} /> : ""}
        </h3>
      </div>
      <hr />
      <h2>Xoids First Mission</h2>
      <h3>
        Mission One CTZN Balance :{" "}
        {web3State?.missionOneCtznBalance
          ? regexVal(web3State?.missionOneCtznBalance)
          : 0}
      </h3>
      <h3>
        People Staked: {userXoidEvents ? userXoidEvents.length : "Loading..."}
      </h3>
      <h3>CTZN Paid: {userCtznEvents ? regexVal(userCtznEvents) : "0"}</h3>
      <h3>
        Remaining CTZN to Payout:{" "}
        {ctznWillPayoutTotal ? regexVal(ctznWillPayoutTotal - web3State.missionOneCtznBalance) : "Loading..."}
      </h3>
      <h3>
        Registered Wallet Count:{" "}
        {registeredWalletCounts ? regexVal(registeredWalletCounts) : "Loading"}
      </h3>
    </div>
  );
}

export default App;
 