import { stakingPoolABI } from "./contract/staking";
import { lpPoolABI } from "./contract/lp";
import { ctznAbi } from "./contract/ctznAbi";
import Web3 from "web3";

export let web3State = {
  web3: null,
  web3Eth: null,
  account: null,
  stakeContract: null,
  lpContract: null,
  blockNumber: null,
  rpc: "https://speedy-nodes-nyc.moralis.io/be6a6bda1bde6dd3b0eeaba5/bsc/mainnet",
  ethRpc: "https://speedy-nodes-nyc.moralis.io/be6a6bda1bde6dd3b0eeaba5/eth/mainnet",
  lpAddress: "0xB357357B7C9bB5ac5DC549Ecd6608cb433D1Ed0f",
  stakingAddress: "0x507597dEb240814b658c2a5fccdE34eE70B04F83",
  stakeTransactionHash:
    "0x44b97e19765e3043d9039620c0fc3abe7b0c2e95eabd1c343b05906d68992637",
  lpTransactionHash:
    "0x44b97e19765e3043d9039620c0fc3abe7b0c2e95eabd1c343b05906d68992637",
  stakeBlockNum: null,
  lpBlockNum: null,
  cronWalletAddress: "0x6934b7875fEABE4FA129D4988ca6DEcD1Dca9C2B",
  ctznAddress: "0xA803778AB953d3FfE4FBD20Cfa0042eCeFE8319D",
  missionOneAddress: "0x6Dd2D9aEe249afE49f89D0A62f192c7233bB366e",
  ctznContract: null,
  ctznEthContract: null,
  ctznBalance: null,
  missionOneCtznBalance: null,
  ctznEthAddress: "0xA803778AB953d3FfE4FBD20Cfa0042eCeFE8319D"
};

export const readEvents = async (contract, event, from, to) => {
  try {
    let events = await contract.getPastEvents(event, {
      fromBlock: from,
      toBlock: to,
    });

    return events;
  } catch (error) {}
};
export const loadBlockchain = async () => {
  try {
    web3State.web3 = new Web3(web3State.rpc);
    let web3 = web3State.web3;

    web3State.web3Eth = new Web3(web3State.ethRpc);
    let web3Eth = web3State.web3Eth;

    web3State.stakeContract = new web3.eth.Contract(
      stakingPoolABI,
      web3State.stakingAddress
    );
    web3State.lpContract = new web3.eth.Contract(
      lpPoolABI,
      web3State.lpAddress
    );
    web3State.ctznContract = new web3.eth.Contract(
      ctznAbi,
      web3State.ctznAddress
    );

    web3State.ctznEthContract = new web3Eth.eth.Contract(
        ctznAbi,
        web3State.ctznEthAddress
      );

    let stakeReceipt = await web3.eth.getTransactionReceipt(
      web3State.stakeTransactionHash,
      function (err, data) {
        return data;
      }
    );
    let lpReceipt = await web3.eth.getTransactionReceipt(
      web3State.lpTransactionHash,
      function (err, data) {
        return data;
      }
    );
    web3State.stakeBlockNum = stakeReceipt.blockNumber;
    web3State.lpBlockNum = lpReceipt.blockNumber;
    web3State.ctznBalance = await web3State.ctznContract.methods
      .balanceOf(web3State.cronWalletAddress)
      .call();
    web3State.ctznBalance = web3State.ctznBalance / 10 ** 18;

    web3State.missionOneCtznBalance = await web3State.ctznEthContract.methods
      .balanceOf(web3State.missionOneAddress)
      .call();
      console.log("missionOneCtznBalance", web3State.missionOneCtznBalance)
    web3State.missionOneCtznBalance = web3State.missionOneCtznBalance / 10 ** 18;
      console.log("missionOneCtznBalance", web3State.missionOneCtznBalance)
      
  } catch (error) {
    console.log("error", error);
  }
};
